import React from "react";
import $ from 'jquery';
window.jQuery = window.$ = $;

const About = () => {
    $(function () {
        var filterList = {
            init: function () {
                $('#team-info').mixitup({
                    targetSelector: '.portfolio',
                    filterSelector: '.filter',
                    effects: ['fade'],
                    easing: 'snap'
                });
            }
        };
        filterList.init();
    });

    return (
        <>
            <div id="inter" className="about">
                <div id="banner">
                    <div id="head-info">
                        <span className="top"></span>
                        <h3>About MyBells</h3>
                        <h2>傳統西洋婚禮中當教堂響起清脆響亮的鐘聲時<br />代表又一對新人互許終生結為連理</h2>
                        <span className="bottom"></span>
                    </div>
                </div>
                <div id="content-wp">
                    <div className="inner">
                        <h1>
                            貝爾斯 成員經歷豐富。擅長多元曲風，西洋經典/爵士/ Bossa Nova /搖滾樂 / 懷舊老歌，營造經典浪漫的婚禮氛圍<br />
                            用歌聲呈現溫馨感人情境；輕快活潑的愉悅；表達溫柔深情的甜蜜，貝爾斯 珍惜每對新人們，一生一次的婚禮<br />用幸福開啟下一段人生。
                            傳達對於每對新人的滿滿祝福，與您分享幸福，分享快樂。
                        </h1>
                        <div id="team">
                            <h2>貝爾斯團隊</h2>
                            <ul id="sort" className="clearfix">
                                <li><span className="filter active" data-hover="全部成員" data-filter="Singer Keyboard Violin Saxophones Drummer">All</span></li>
                                <li><span className="filter" data-hover="主持/歌手" data-filter="Singer">Singer</span></li>
                                <li><span className="filter" data-hover="琴師" data-filter="Keyboard">Keyboard</span></li>
                                <li><span className="filter" data-hover="小提琴手" data-filter="Violin">Violin</span></li>
                                <li><span className="filter" data-hover="薩克斯風" data-filter="Saxophones">Saxophones</span></li>
                                <li><span className="filter" data-hover="吉他" data-filter="Guitar">Guitar</span></li>
                            </ul>
                            <div id="team-info">
                                <div className="GITheWall">
                                    <ul id="list" className="clearfix">
                                        <li className="portfolio Singer" data-cat="Singer">
                                            <img src={require("../assets/images/team/niuniu.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Saxophones"  data-cat="Saxophones">
                                            <img src={require("../assets/images/team/ken.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Singer"  data-cat="Saxophones">
                                            <img src={require("../assets/images/team/leo.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Saxophones"  data-cat="Saxophones">
                                            <img src={require("../assets/images/team/ewn-aij.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Violin" data-cat="Violin">
                                            <img src={require("../assets/images/team/to-bu.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Guitar"  data-cat="Guitar">
                                            <img src={require("../assets/images/team/maxi.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Violin" data-cat="Violin">
                                            <img src={require("../assets/images/team/xve-lc.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Violin" data-cat="Violin">
                                            <img src={require("../assets/images/team/kov-ibm.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Violin" data-cat="Violin">
                                            <img src={require("../assets/images/team/sa-pip.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Singer" data-cat="Singer">
                                            <img src={require("../assets/images/team/kat-kal.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Keyboard" data-cat="Keyboard">
                                            <img src={require("../assets/images/team/dgy-cae.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Keyboard" data-cat="Keyboard">
                                            <img src={require("../assets/images/team/bto-epf.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Violin" data-cat="Violin">
                                            <img src={require("../assets/images/team/lee-lkw.jpg")} alt="藝人照片" />
                                        </li>
                                        <li className="portfolio Violin" data-cat="Violin">
                                            <img src={require("../assets/images/team/xve-kba.jpg")} alt="藝人照片" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;  