import React from "react";

const Showcase = () => {
    return (
        <div id="inter" className="contact">
            <div id="banner">
                <div className="top-bg"></div>
                <div className="inner">
                    <div id="head-info">
                        <span className="top"></span>
                        <h3>Contact MyBells</h3>
                        <h2>通往幸福彼端的紅毯<br />讓 貝爾斯 陪您一同走過</h2>
                        <span className="bottom"></span>
                    </div>
                </div>
            </div>
            <div id="content-wp">
                <div id="form">
                    <dl className="clearfix">
                        <dd id="left">
                            <div className="info">
                                <span className="icon"></span>
                                <a href="http://line.me/ti/p/%40dux6813u">
                                    <img src={require("../assets/images/line.png")} alt="聯絡圖" className="line" />
                                    {" "}
                                </a>
                            </div>
                        </dd>
                        <dd id="right">
                            <form className="cami_system_form" action="" >
                                <input type="hidden" name="_cami_system_mode" value="operator" />
                                <input type="hidden" name="_cami_system_assign_to" value="34b62b692126e18a7dd86dc98839d167836561ba" />
                                <input type="hidden" name="_cami_system_redirect" value="" />
                                <input type="text" name="name" value="" placeholder="姓名(必填)" />
                                <input type="text" name="phone" value="" placeholder="電話(必填)" />
                                <input type="text" name="email" value="" placeholder="信箱(必填)" />
                                <textarea name="content" id="" cols="30" rows="10" placeholder="內容(必填)"></textarea>
                                <input className="code" type="text" name="_cami_system_verify" placeholder="請輸入右邊驗證碼" /> <span className="cami_system_verify">
                                    <img src="https://25.cami.yesing.com/images/client/verify-loader.gif" alt="驗證碼讀取失敗" /></span>
                                <input type="submit" value="送出" />
                            </form>
                        </dd>
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default Showcase;  