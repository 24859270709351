
import React from "react";
import $ from 'jquery';
import { NavLink } from 'react-router-dom';

const IndexMain = () => {
    $(function () {
        $('#fullwidth_slider').everslider({
            mode: 'carousel',
            maxVisible: 5,
            moveSlides: 1,
            slideEasing: 'easeInOutCubic',
            slideDuration: 700,
            itemKeepRatio: true,
            navigation: true,
            maxWidth: '100%',
            keyboard: true,
            nextNav: '<span class="alt-arrow">Next</span>',
            prevNav: '<span class="alt-arrow">Next</span>',
            ticker: true,
            tickerAutoStart: true,
            tickerHover: true,
            tickerTimeout: 2000
        });
    });

    return (
        <>
            <div id="video1">
                <div id="ind-service">
                    <div id="item">
                        <NavLink to="/page/About/" title="關於我們">
                            <div className="thin-line"></div>
                            <div className="wide-line"></div>
                            <h3>About Us</h3>
                            <span className="one"></span>
                            <h2>關於我們<p>用歌聲獻上祝福 陪您走向幸福人生</p></h2>
                        </NavLink>
                        <NavLink to="/page/Service/" title="服務項目">
                            <div className="thin-line"></div>
                            <div className="wide-line"></div>
                            <h3>Service</h3>
                            <span className="two"></span>
                            <h1>服務項目<p>婚禮主持 活動尾牙 婚禮樂團</p></h1>
                        </NavLink>
                        <NavLink to="/page/Showcase/" title="工作實錄">
                            <div className="thin-line"></div>
                            <div className="wide-line"></div>
                            <h3>Showcase</h3>
                            <span className="three"></span>
                            <h2>工作實錄<p>此生的美好幸福  值得再三回味</p></h2>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div id="content-wp">
                <section>
                    <dl id="intro">
                        <dd id="about">
                            <article>
                                <div className="w1200">
                                    <h3>About MyBells</h3>
                                    <p>當清脆悅耳的鐘聲響起，幸福盈滿會場。門扉緊掩的彼側，新人緊緊牽著彼此的手聆聽。<br />
                                        裡邊舉行的那一場婚禮，將為祝褔我倆而舉行。推開門扉，不僅是踏進禮堂，更是邁向紅毯彼端的幸福。<br />
                                        傳統西洋婚禮中，教堂響起清脆響亮的鐘聲時，代表一對新人互許終生結為連理。透過嘹亮的鐘響，將幸福傳遞至各處與眾人分享。
                                    </p>
                                    <h2>關於我們</h2>
                                </div>
                            </article>
                        </dd>
                        <dd id="memory">
                            <aside>
                                <h3>Memory</h3>
                                <h2>新人將一生的幸福託付給 貝爾斯，貝爾斯必定是忠於所託，將婚禮刻畫成此生最雋永的回憶</h2>
                                <div id="slider">
                                    <div id="fullwidth_slider" className="everslider fullwidth-slider">
                                        <ul className="es-slides">
                                            <li><img src={require('../assets/images/pic5.jpg')} alt="婚禮樂團" /></li>
                                            <li><img src={require('../assets/images/pic6.jpg')} alt="婚禮歌手" /></li>
                                            <li><img src={require('../assets/images/pic16.jpg')} alt="婚禮音樂" /></li>
                                            <li><img src={require('../assets/images/pic7.jpg')} alt="婚禮主持人" /></li>
                                            <li><img src={require('../assets/images/pic8.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic9.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic10.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic13.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic1.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic2.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic11.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic12.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic14.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic3.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic4.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic15.jpg')} alt="圖片介縉" /></li>
                                            <li><img src={require('../assets/images/pic17.jpg')} alt="圖片介縉" /></li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </dd>
                    </dl>
                </section>
            </div>
        </>
    );
};

export default IndexMain;  