
import React from "react";
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <div className="w1200">
                <h2>
                    <div className="contact">
                        <span className="contact-icn">Contact Us</span>
                    </div>
                    <div className="line">
                        <a href="https://line.me/ti/p/H-5Xao_6B9" target="_blank" rel="noreferrer">
                            <img src={require('./assets/images/line.png')} alt="line" />
                        </a>
                        <b>
                            <i>Line <b>線上洽詢</b></i>
                            <p>直接掃瞄【QR Code】手機開啟連結<a href="https://line.me/ti/p/H-5Xao_6B9" target="_blank" rel="noreferrer">開啟LINE</a></p>
                        </b>
                        <a href="https://www.youtube.com/channel/UCm7fgwMS0hDRKt4HgfcRCHg" target="_blank" className="youtube" rel="noreferrer">
                            <img src={require('./assets/images/youtube.png')} alt="line" />
                        </a>
                        <a href="https://www.instagram.com/mybells.music/" target="_blank" className="instagram" rel="noreferrer">
                            <img src={require('./assets/images/instagram.png')} alt="line" />
                        </a>
                    </div>
                </h2>
                <ul className="menu clearfix">
                    <li>
                        <NavLink to="/page/About/" title="關於我們">關於我們</NavLink>
                    </li>
                    <li>
                        <NavLink to="/page/Service/" title="服務項目">服務項目</NavLink>
                    </li>
                    <li>
                        <NavLink to="/page/Album/" title="工作剪影">工作剪影</NavLink>
                    </li>
                    <li>
                        <NavLink to="/page/Showcase/" title="工作實錄">工作實錄</NavLink>
                    </li>
                    <li>
                        <NavLink to="/page/Contact/" title="聯絡我們">聯絡我們</NavLink>
                    </li>
                    <li>
                        <a href="http://www.tsj-diamond.com/" target="_blank" rel="noreferrer">TSJ 鑽石設計</a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Footer;  