import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import $ from "jquery";

import "./assets/scss/style.scss";

window.jQuery = window.$ = $;

const Layout = (props) => {
    require("./assets/js/other");
    require("./assets/js/classie");
    require("./assets/js/dw-function");
    require("./assets/js/fixheader");
    require("./assets/js/venobox.min");
   

    return (
        <div id="outer-wp">
            <Nav />
            {props.children}
            <footer>
                <Footer />
            </footer>
        </div>
    );
};

export default Layout;
