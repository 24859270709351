
import React from "react";
import { NavLink } from 'react-router-dom';

const Nav = () => {
    return (
        <header className="fix-nav">
            <nav>
                <div className="w1200">
                    <div className="logo"></div>
                    <a href="/#" className="logo-text">
                        {" "}
                    </a>
                    <button id="trigger-overlay" type="button">
                        Open Overlay
                    </button>
                    <div className="overlay overlay-slidedown">
                        <button type="button" className="overlay-close">
                            Close
                        </button>
                        <ul id="nav">
                            <li>
                                <NavLink to="/page/About/" title="關於我們">
                                    <h2>artist<p>關於我們</p></h2>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/page/Service/" title="服務項目">
                                    <h2>service<p>服務項目</p></h2>
                                </NavLink>
                            </li>
                            <li className="mid-space">
                                <NavLink to="/page/Album/" title="工作剪影">
                                    <h2>album<p>工作剪影</p></h2>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/page/Showcase/" title="工作實錄">
                                    <h2>ShowCase<p>工作實錄</p></h2>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/page/Contact/" title="聯絡我們">
                                    <h2>conatct<p>聯絡我們</p></h2>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/" title="回首頁">
                                    <h2>Home<p>回首頁</p></h2>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Nav;  