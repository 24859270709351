import React from "react";
import $ from 'jquery';


const Service = () => {
    $(function () {
        $('.album #photowall').mixitup({
            showOnLoad: 'wedding',
            targetSelector: '.portfolio',
            filterSelector: '.filter',
            effects: ['scale'],
            easing: 'snap'
        });
        $("#photowall ul li a").imgLiquid();
        $('.venobox').venobox(); 
    });
    return (
        <div id="inter" className="album">
            <div id="banner">
                <div id="head-info">
                    <span className="top"></span>
                    <h3>MyBells Show</h3>
                    <h2>傳統西洋婚禮中當教堂響起清脆響亮的鐘聲時<br />代表又一對新人互許終生結為連理</h2>
                    <span className="bottom"></span>
                </div>
            </div>
            <div id="content-wp">
                <ul id="menu" className="clearfix">
                    <li><span className="filter " data-filter="wedding">新人合影</span></li>
                    <li><span className="filter" data-filter="showcase">婚禮演出</span></li>
                </ul>
                <div id="photowall">
                    <div className="inner">
                        <ul className="list clearfix">
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/20.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/20.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/19.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/19.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/18.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/18.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/4.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/4.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/17.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/17.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/3.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/3.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/2.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/2.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio wedding" data-cat="wedding" data-gall="myGallery">
                                <a className="venobox" href={require("../assets/images/photo/2015/wedding/15.jpg")}>
                                    <img src={require("../assets/images/photo/2015/wedding/15.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2014/showcase/17.jpg")}>
                                    <img src={require("../assets/images/photo/2014/showcase/17.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2014/showcase/13.jpg")}>
                                    <img src={require("../assets/images/photo/2014/showcase/13.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2015/showcase/14.jpg")}>
                                    <img src={require("../assets/images/photo/2015/showcase/14.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2015/showcase/11.jpg")}>
                                    <img src={require("../assets/images/photo/2015/showcase/11.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2015/showcase/12.jpg")}>
                                    <img src={require("../assets/images/photo/2015/showcase/12.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2014/showcase/21.jpg")}>
                                    <img src={require("../assets/images/photo/2014/showcase/21.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2015/showcase/1.jpg")}>
                                    <img src={require("../assets/images/photo/2015/showcase/1.jpg")} alt="照片" />
                                </a>
                            </li>
                            <li className="portfolio showcase" data-cat="showcase">
                                <a className="venobox" href={require("../assets/images/photo/2015/showcase/2.jpg")}>
                                    <img src={require("../assets/images/photo/2015/showcase/2.jpg")} alt="照片" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;  