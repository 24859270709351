/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import $ from 'jquery';

// import TweenMax from './TweenMax.min';
import './imgLiquid';
import './parallax';
import './everslider.min';
import './mixitup.min';


$(document).ready(function() {
	$("#memory").css({backgroundSize: "cover"});
	
	// 服務項目 包套方圖 底圖
	$('.service #item').parallax({
		imageSrc: '/images/service-item-bg.jpg',
		positionX:'right',
		positionY:'top',
		zIndex:'1',
		naturalWidth:'1920',
		naturalHeight:'1304'
	});
	
	
	
	$('#fullwidth_slider').everslider({
		mode: 'carousel',
		maxVisible: 5,
		moveSlides: 1,
		slideEasing: 'easeInOutCubic',
		slideDuration: 700,
		itemKeepRatio: true,
		navigation: true,
		maxWidth: '100%', 
		keyboard: true,
		nextNav: '<span class="alt-arrow">Next</span>',
		prevNav: '<span class="alt-arrow">Next</span>',
		ticker: true,
		tickerAutoStart: true,
		tickerHover: true,
		tickerTimeout: 2000
	});

	$(".overlay-close").on("click", function () {
		$(".overlay-slidedown").removeClass("open");
	});
	$("#trigger-overlay").on("click", function () {
		$(this).addClass("active");
		$(".overlay-slidedown").addClass("open");
	});
	$(".overlay ul li a").on("click", function () {
		$(".overlay-slidedown").removeClass("open");
	});
}); 
