import React from "react";
import "../assets/scss/_service.scss";


const Service = () => {

    return (
        <div id="inter" className="service">
            <div id="banner">
                <div id="head-info">
                    <span className="top"></span>
                    <h3>MyBell Services</h3>
                    <h2>傳統西洋婚禮中當教堂響起清脆響亮的鐘聲時<br />代表又一對新人互許終生結為連理</h2>
                    <span className="bottom"></span>
                </div>
            </div>
            <div id="content-wp">
                <div className="inner">
                    <dl id="content">
                        <dd>
                            <span className="one"></span>
                            <h1>樂團表演</h1>
                            <h2>節奏藍調 / 爵士 / R&B / BossaNova<br />多樣的演奏曲風，精彩的歌唱表演<br />貝爾斯 為您呈現專業完美的樂團演出</h2>
                        </dd>
                        <dd>
                            <span className="two"></span>
                            <h1>婚禮主持</h1>
                            <h2>婚禮的節奏宛如歌聲的抑揚頓挫<br />婚禮的氛圍就像歌聲的曲風旋律<br />貝爾斯專業婚禮主持，讓婚禮進行更完美，讓賓主盡歡其中</h2>
                        </dd>
                        <dd>
                            <span className="three"></span>
                            <h1>婚禮舞蹈</h1>
                            <h2>讓您的婚禮一進/二進有不同的視覺感受<br /> 也讓您們再次成為賓客注意的焦點 <br /> 充滿美滿回憶 一生不忘</h2>
                        </dd>
                    </dl>
                </div>
                <div id="item">
                    <div className="inner">
                        <h3><i></i>婚禮包套方案<b></b></h3>
                        <h2>
                            婚禮締良緣，一生恆雋永。
                            貝爾斯 期盼每對新人都擁有值得珍藏一生的婚禮回憶<br />以平實的價格提供完整規劃的婚宴服務，包裝幸福的婚禮，打造永恆的回憶
                        </h2>
                        <ul id="list" className="clearfix">
                            <li>
                                <img src={require("../assets/images/service-img2.jpg")} alt="組合照片" />
                                <h4>組合一</h4>
                                <h2>經典基本</h2>
                                <ol>
                                    <li>專業司儀乙名</li>
                                    <li>婚禮音樂設計(二次進場音樂)</li>
                                    <li>婚禮節目流程規劃</li>
                                    <li>專業 PA 音響設備</li>
                                </ol>
                            </li>
                            <li>
                                <img src={require("../assets/images/service-img1.jpg")} alt="組合圖片" />
                                <h4>組合二</h4>
                                <h2>浪漫流行</h2>
                                <h3>歌手兼司儀、Keybord、小提琴or薩克斯風</h3>
                                <ol>
                                    <li>婚禮樂團現場三重奏</li>
                                    <li>婚禮音樂設計(二次進場音樂)</li>
                                    <li>婚禮節目流程規劃</li>
                                    <li>專業 PA 音響設備</li>
                                </ol>
                            </li>
                            <li>
                                <img src={require("../assets/images/service-img3.jpg")} alt="組合圖片" />
                                <h4>組合三</h4>
                                <h2>典藏演奏</h2>
                                <h3>keybord、小提琴、薩克斯風、爵士鼓</h3>
                                <ol>
                                    <li>專業司儀乙名</li>
                                    <li>婚禮音樂設計(二次進場音樂)</li>
                                    <li>婚禮節目流程規劃</li>
                                    <li>專業 PA 音響設備</li>
                                </ol>
                            </li>
                            <li>
                                <img src={require("../assets/images/service-img4.jpg")} alt="組合圖片" />
                                <h4>組合四</h4>
                                <h2>完整珍藏</h2>
                                <h3>雙歌手兼司儀、keybord、吉他、貝斯、爵士鼓</h3>
                                <ol>
                                    <li>婚禮音樂設計(二次進場音樂)</li>
                                    <li>婚禮節目流程規劃</li>
                                    <li>專業 PA 音響設備</li>
                                </ol>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;  