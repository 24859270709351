import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './Layout';
import About from './page/About';
import Service from './page/Service';
import IndexMain from "./page/IndexMain";
import Album from "./page/Album";
import Showcase from "./page/Showcase";
import Contact from "./page/Contact";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter>
		<Layout>
			<Routes>
				<Route path="/" exact element={<IndexMain />} />
				<Route path="/page/About" element={<About />} />
				<Route path="/page/Service" element={<Service />} />
				<Route path="/page/Album" element={<Album />} />
				<Route path="/page/Showcase" element={<Showcase />} />
				<Route path="/page/Contact" element={<Contact />} />
			</Routes>
		</Layout>
	</BrowserRouter>
);