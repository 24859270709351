import React from "react";
import $ from 'jquery';


const Showcase = () => {
    $(function(){
        $('.showcase #videowall').mixitup({
            showOnLoad: 'wedding',
            targetSelector: '.portfolio',
            filterSelector: '.filter',
            effects: ['fade'],
            easing: 'snap'
        });
        $('.venobox').venobox(); 
    });
    
    return (
        <div id="inter" className="showcase">
            <div id="banner">
                <div id="head-info">
                    <span class="top"></span>
                    <h3>MyBells Show</h3>
                    <h2>傳統西洋婚禮中當教堂響起清脆響亮的鐘聲時<br />代表又一對新人互許終生結為連理</h2>
                    <span class="bottom"></span>
                </div>
            </div>
            <div id="content-wp">
                <div id="videowall">
                    <ul class="list clearfix">
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/84OCiWus040">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/23.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/uhAURBDTTdM">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/24.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/GjBEMw3kB3I">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/22.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/eGVKvnFp_Js">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/21.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/OSs0t5mhC5M">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/20.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/H4YP61vf1r0">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/4.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/l9m-KiDaC0E">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/5.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/lpJx0x_OFPE">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/12.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/dvm27XJLvM0">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/19.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/HMxOvZF603c">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/17.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/FdiVvdj5iV4">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/13.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/Y2tk8X97FgU">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/1.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/wdyJuK4CYuc">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/18.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/EgiHwPk0UZA">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/16.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/QorTyshW4w0">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/15.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/qEqQdCahrcU">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/14.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/Pdwuk3KoMqY">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/2.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/v6FD-saFtvo">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/10.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/8VABy4bH7ws">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/3.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/GGSqR5s1XSs">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/9.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/L8fl8F2hl3k">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/6.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/N7UjKd7rvG4">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/7.jpg")} alt="案子" />
                            </a>
                        </li>
                        <li>
                            <a className="venobox" data-vbtype="video" href="https://youtu.be/eUQgwMCDnkA">
                                <span><div class="video"></div></span>
                                <img src={require("../assets/images/photo/2015/video/8.jpg")} alt="案子" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Showcase;  